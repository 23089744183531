import Image0 from './AboutFiles/IMG_1068.jpg';
import Image1 from './AboutFiles/IMG_6288_Original (1).JPG';
import Image3 from './AboutFiles/Copy of IMG_2929.jpeg';
import Image4 from './AboutFiles/IMG_3989.jpeg';
import Image5 from './AboutFiles/Copy of IMG_3850 (1).jpg';
import Image6 from './AboutFiles/Copy of IMG_2901 (1).jpg';
import Image7 from './AboutFiles/IMG_6286_Original (1).JPG';
import Image8 from './AboutFiles/IMG_0329.jpg';
import Image9 from './AboutFiles/IMG_0665.jpg';
import Image10 from './AboutFiles/Copy of IMG_6263.jpg';
import Image11 from './AboutFiles/IMG_0666.jpg';
import Image12 from './AboutFiles/IMG_6307_Original.JPG';
import Image13 from './AboutFiles/Copy of IMG_6264.jpg';
import Image14 from './AboutFiles/Copy_of_IMG_2779_(1).jpg';
import Image15 from './AboutFiles/IMG_0674.jpg';
import Image16 from './AboutFiles/IMG_6313_Original.JPG';
import Image17 from './AboutFiles/Copy of IMG_2907 (1).jpeg';
import Image18 from './AboutFiles/Copy_of_IMG_2780_(1).jpg';
import Image19 from './AboutFiles/Copy_of_IMG_1002_(1).jpg';
import Image20 from './AboutFiles/IMG_3991.jpeg';
import Image21 from './AboutFiles/Copy of IMG_6259 (2).jpg';
import Image22 from './AboutFiles/IMG_6309_Original.JPG';
import Image23 from './AboutFiles/Copy_of_IMG_2762_(2).jpg';

const LandscapeImages = [
  Image0,
  Image1,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
  Image17,
  Image18,
  Image19,
  Image20,
  Image21,
  Image22,
  Image23,
]

export default LandscapeImages;