import Image0 from './Files/IMG_4058.jpg';
import Image1 from './Files/IMG_1068.jpg';
import Image2 from './Files/IMG_5971.JPG';
import Image3 from './Files/IMG_4059.jpg';
import Image4 from './Files/IMG_2748.jpg';
import Image5 from './Files/IMG_6288_Original (1).JPG';
import Image7 from './Files/Copy of IMG_2929.jpeg';
import Image8 from './Files/IMG_3989.jpeg';
import Image9 from './Files/Copy of IMG_3850 (1).jpg';
import Image10 from './Files/Copy of IMG_2901 (1).jpg';
import Image11 from './Files/IMG_6286_Original (1).JPG';
import Image12 from './Files/IMG_0329.jpg';
import Image13 from './Files/IMG_0665.jpg';
import Image14 from './Files/IMG_6567.jpg';
import Image15 from './Files/Copy of IMG_6263.jpg';
import Image16 from './Files/IMG_6149.jpg';
import Image17 from './Files/IMG_0666.jpg';
import Image18 from './Files/IMG_6571.jpg';
import Image19 from './Files/IMG_6307_Original.JPG';
import Image20 from './Files/Copy of IMG_6264.jpg';
import Image21 from './Files/Copy of CA02C94C-7B72-45F7-8011-D0CB8FC05237.jpeg';
import Image22 from './Files/Copy_of_IMG_2779_(1).jpg';
import Image23 from './Files/IMG_0489.jpg';
import Image24 from './Files/IMG_0674.jpg';
import Image25 from './Files/IMG_6313_Original.JPG';
import Image26 from './Files/IMG_0503.jpg';
import Image27 from './Files/Copy of IMG_2907 (1).jpeg';
import Image28 from './Files/IMG_6631.jpg';
import Image29 from './Files/IMG_8755.jpg';
import Image30 from './Files/IMG_6593.jpg';
import Image31 from './Files/IMG_1202.jpg';
import Image32 from './Files/Copy_of_IMG_2780_(1).jpg';
import Image33 from './Files/IMG_6582.jpg';
import Image34 from './Files/IMG_6152.jpg';
import Image35 from './Files/IMG_6260_Original (2).JPG';
import Image36 from './Files/IMG_6580.jpg';
import Image37 from './Files/Copy of IMG_3539.JPG';
import Image38 from './Files/IMG_6153.jpg';
import Image39 from './Files/IMG_6147.jpg';
import Image40 from './Files/IMG_2742.jpg';
import Image41 from './Files/IMG_6135.jpg';
import Image42 from './Files/IMG_1079_(1).jpg';
import Image43 from './Files/Copy of FCF7E6B3-034C-4419-B783-48DC62256E9E (1).jpeg';
import Image44 from './Files/Copy_of_IMG_1002_(1).jpg';
import Image45 from './Files/IMG_3991.jpeg';
import Image46 from './Files/IMG_4057.jpg';
import Image47 from './Files/IMG_6127.jpg';
import Image48 from './Files/Copy of IMG_6259 (2).jpg';
import Image49 from './Files/IMG_6309_Original.JPG';
import Image50 from './Files/Copy_of_IMG_2762_(2).jpg';

const Images = [
  Image0,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
  Image17,
  Image18,
  Image19,
  Image20,
  Image21,
  Image22,
  Image23,
  Image24,
  Image25,
  Image26,
  Image27,
  Image28,
  Image29,
  Image30,
  Image31,
  Image32,
  Image33,
  Image34,
  Image35,
  Image36,
  Image37,
  Image38,
  Image39,
  Image40,
  Image41,
  Image42,
  Image43,
  Image44,
  Image45,
  Image46,
  Image47,
  Image48,
  Image49,
  Image50,
]

export default Images;